@import "../../_globalColor";

.dev-hidden-img {
  position: absolute;
  width: 0px;
  height: 0px;
}

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin: 2rem 2rem 0rem 2rem;
  text-align: center;
}

.dev-icons-picture {
  width: 3rem;
  height: 3rem;
  filter: saturate(0);
}

.dev-icons-picture.hideOnHover {
  visibility: visible;
}

.dev-icons-picture-saturated {
  width: 3rem;
  height: 3rem;
  visibility: hidden;
  position: absolute;
  z-index: 1;
}

.tooltip {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  margin-top: 0.4rem;
  border-radius: 1rem;
  padding: 0.5rem;
  width: 10rem;
  line-height: 1.5rem;
  display: block;
  transform: translate(-35%, 0);
  position: absolute;
  z-index: 1;
}

.toolTipHead1 {
  font-size: 1.5rem;
}
.toolTipSubTitle {
  font-size: 0.6rem;
  margin-bottom: 0.3rem;
}
.toolTipHead2 {
  text-align: left;
  margin-left: 0.3rem;
  font-size: 1rem;
  line-height: 1rem;
}
.tooltipList {
  padding-left: 1.1rem;
  padding-top: 0.2rem;
}
.toolTipBulletPoint {
  flex-direction: row;
  font-size: 0.6rem;
  margin-bottom: 0.3rem;
  line-height: 0.7rem;
  text-align: left;
  overflow-wrap: break-word;
  width: 8.5rem;
}

.noUnderline {
  text-decoration: none;
}
.toolTipStars {
  margin-top: 0.3rem;
  font-size: 1rem;
  letter-spacing: 0.5rem;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: Center;
}

.software-skill-white > p {
  color: white !important;
}

.software-skill-inline:hover .dev-icons-picture {
  filter: saturate(1);
}

.software-skill-inline:hover .dev-icons-picture-saturated {
  visibility: visible;
}

.software-skill-inline:hover .dev-icons-picture.hideOnHover {
  visibility: hidden;
}

.software-skill-inline:hover .tooltip {
  visibility: visible;
}

.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}

.software-skill-canvas {
  position: relative;
  width: 100%;
  height: 82.5%;
  margin-top: 5%;
}

.software-skill-canvas {
  position: relative;
  width: 100%;
  height: 82.5%;
  margin-top: 5%;
}

.software-skill-pointer {
  cursor: pointer;
}

@media (max-width: 768px) {
  .software-skill-canvas {
    visibility: hidden;
    position: absolute;
  }
}
