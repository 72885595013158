
.web-canvas {
  position: relative;
  width: 100%;
  height: 89.5%;
  margin-top: 5%;
}

.hidden {
  position: absolute;
  width: 0px;
  height: 0px;
}
