@import "../../_globalColor";

.experience-card {
  display: flex;
  flex-direction: row;
}

.experience-card:hover + .experience-card-border {
  border-color: $educationCardBorder;
  width: 100%;
  -webkit-transition: width 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out;
  -o-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
}

.experience-card-border {
  height: 30px;
  width: 80%;
  border-top: 2px solid $lightBorder1;
  margin-bottom: 20px;
  -webkit-transition: width 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out;
  -o-transition: width 0.6s ease-in-out;
  transition: width 0.6s ease-in-out;
}

.experience-head {
  display: flex;
  flex-direction: row;
}

.experience-text-place {
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 27px;
}

.experience-roundedimg {
  object-fit: cover;
  margin-right: 2rem;
  width: 6rem;
  height: 6rem;
  box-shadow: 0 0.5rem 1rem $lightBoxShadowDarker;
  border-radius: 50%;
}

.experience-card-right {
  max-width: 70%;
}

.experience-text-subHeader {
  color: $textColor;
  font-weight: 700;
  font-size: 21px;
  margin: 0px;
  //padding-top: 0.8rem;
  line-height: normal;
}

.experience-text-duration {
  color: $titleColor;
  font-size: 19px;
  margin: 0px;
  opacity: 50%;
  padding-top: 0.5rem;
}

.experience-text-desc {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  opacity: 85%;
}

.experience-text-bullets > ul {
  margin-top: 0.5rem;
  opacity: 85%;
}

.experience-single-wrapper {
  padding-top: 0.8rem;
}


.experience-bar {
  list-style: none;
  padding-left: 0.25rem;
}
.experience-bar >li {
  padding-bottom: 1.5rem;
  position: relative;
  display:flex
}
.experience-bar>li:before {
  content: '\25CF';
  margin-right: 10px;
  font-size: 1.5rem;
  color: white;
  opacity: 100%;
}
.experience-bar>li:after {
  position: absolute;
  left: 0;
  top: 1.5rem;
  content: '';
  border-left: 0.3rem solid white;
  margin-left: 0.2875rem;
  height: calc(100% - 1.5rem);
  opacity: 0.25;
}

.experience-bar >li:last-of-type:after {
  height: 0px;
}

/* Media Query */
@media (max-width: 1380px) {
  .experience-card {
    flex-direction: column;
    padding-bottom: 10px;
  }
  .experience-card-right {
    max-width: 90%;
  }
  .experience-card-border {
    width: 90%;
  }

  .experience-text-details{
    width: 95%;
  }
  .experience-text-subHeader {
    padding-top: 0.5rem;
    font-size: 16px;
  }

  .experience-text-place {
    font-size: 20px;
    height: 23px;
  }

  .experience-text-duration {
    font-size: 16px;
  }
  .experience-roundedimg {
    width: 4rem;
    height: 4rem;
    margin-bottom: 0.5rem;
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .experience-card {
    text-align: center;
  }
  .experience-card-right {
    max-width: 100%;
  }
  .experience-card-border {
    width: 100%;
  }
  .experience-roundedimg {
    margin-top: 10px;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .experience-text-desc {
    text-align: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .experience-text-bullets {
    text-align: left;
  }
  .experience-text-bullets > ul {
    max-width: 100%;
    margin: auto;
  }
}
