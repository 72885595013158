@import "../../_globalColor";

.ref-title {
  font-size: 52px;
  font-weight: 400;
  line-height: normal;
}

.ref-cards-div-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 550px));
  gap: 1rem 1rem;
}

.ref-card {
  color: $githubRepoCardColor;
  background-color: $lightBackground2;
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  padding: 1.5rem;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  width: auto;
}

.ref-card:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}

.dark-card-mode {
  background-color: $darkBackground;
  color: $textColorDark;
}
.dark-card-mode p {
  color: $textColorDark;
}
.dark-card-mode:hover {
  background-color: $buttonColor !important;
  box-shadow: 0px 0px 16px $darkBoxShadow;
}

.ref-card-left {
  width: 20%;
  min-width: 9rem;
  display: flex;
}

.ref-image {
  background-color: white;
  object-fit: cover;
  margin-right: 2rem;
  width: 8rem;
  height: 8rem;
  box-shadow: 0 0.5rem 1rem $lightBoxShadowDarker;
  border-radius: 50%;
  align-self: center;
}

.ref-card-right {
  width: 80%;
  padding-top: 0.2rem;
  display: flex;
  flex-direction: column;
}

.ref-name {
  font-weight: bold;
}

.ref-job-title {
  padding-top: 0.5rem;
  font-size: 80%;
}

.ref-location {
  font-size: 80%;
}

.ref-link-box {
  padding-top: 1rem;
  transform: scale(0.75);
  transform-origin: top left;
}

@media (max-width: 768px) {
  .ref-cards-div-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .ref-card-left {
    min-width: 30vw;
  }

  .ref-image {
    width: 25vw;
    height: 25vw;
  }
}
