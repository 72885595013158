@import "../_globalColor";

.dark-mode {
  background-color: $darkBackground;
  color: white;
  transition: "0.1s";
}

.lite-mode {
  background-color: $lightBackground;
  transition: "0.1s";
}
