@import "../../_globalColor";

.gameJam-container {
  display: grid;
  gap: 1rem 1rem;
  font-size: 1rem;
  grid-template-columns: repeat(auto-fit, 400px);
}

.gameJam-video {
  width: 25em;
  height: auto;
  display: block;
  position: absolute;
  align-self: center;
  border-radius: 10px;
  filter: saturate(0.6);
}

.gameJam-video:hover {
  filter: saturate(1);
  transition: filter 0.1s linear;
}

/* Hide Play button + controls on iOS */
gameJam-video::-webkit-media-controls {
  display: none !important;
}

.g-card-image {
  max-width: 80%;
  height: auto;
  max-height: 100%;
  border-radius: 10px;
  align-self: center;
}

.g-card-image.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.g-card-image.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.5s linear;
}

.g-card-title {
  color: $titleColor;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
  margin: 10px 0 0 0;
  background-color: transparent !important;
}

.g-card-subtitle {
  color: $cardSubtitle;
  font-size: 15px;
  line-height: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.8rem;
  background-color: transparent !important;
}

.gameJam-card {
  display: flex;
  width: 25em;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 10px;
  justify-content: center;
}

.gameJam-card-light {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gameJam-card-dark {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.gameJam-detail {
  text-shadow: 1px 1px 0 #000;
  text-align: center;
  pointer-events: none;
  z-index: 1;
}

.gameJam-image {
  position: relative;
  height: 5.625em;
  overflow: hidden;
  text-align: center;
  pointer-events: none;
  visibility: visible;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.gameJam-card-footer span.gameJam-tag {
  background: $buttonColorVideo;
  color: $lightBackground3;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
  pointer-events: auto;
  text-shadow: none;
}

@media (max-width: 925px) {
  .gameJam-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

@media (max-width: 700px) {
  .gameJam-container {
    font-size: 3.7vw;
    gap: 0.1vw;
  }

  .gameJam-card {
    width: 23.5em;
  }

  .gameJam-image {
    margin-top: 0.5em;
    height: 5em;
  }

  .gameJam-detail {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
  }

  .g-card-title {
    font-size: 1.2em;
    line-height: 1.2em;
    margin: 0.5em 0 0 0;
  }

  .g-card-subtitle {
    font-size: 1em;
    line-height: 1.2em;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  .gameJam-card-footer span.project-tag {
    font-size: 1.2em;
    height: 2em;
    line-height: 1.5em;
  }
}
